import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { BaseComponent } from '@base/base.component';
import { Tag, Task, string2Color } from '@models';
import { StoreService } from '@services/store.service';

@Component({
    selector: 'vz-task-id-badge, [vz-task-id-badge]',
    template: `
        <span class="vz-task-id-badge layout center">
            @if (task) {
                <span class="vz-tib-main"
                    [style.background-color]="color"
                    [title]="title">
                    {{ task!.shortId }}
                    @if (task.parentId) {
                        &nbsp;
                    }
                </span>
            }
            @if (task?.parentId) {
                <i class="vz-tib-sub vzi-flow-cascade bg br50p"
                    [style.border-color]="color"
                    [style.color]="color">
                </i>
            }
            @if (task?.subTasks?.length) {
                <span class="vz-tib-parent fs-xs txt-l bg no-wrap"
                    [style.color]="color"
                    [style.border-color]="color">
                    <span class="txt-l">{{ task?.subTasks?.length }}</span>
                    <!-- <span class="txt-ok">{{ done }}</span>
                    <span class="txt-l"> / {{ total }}</span> -->
                </span>
            }
        </span>
    `,
    styles: [`
        @import 'theme';
        :host {
            height: 19px;
            .vz-task-id-badge {
                height: 19px;
                & > .vz-tib-main {
                    color: @textColorInv;
                    border-radius: 4px;
                    padding: 1px 4px;
                    white-space: nowrap;
                    line-height: 1.2;
                    height: 19px;
                }
                & > .vz-tib-sub {
                    padding: 3px 2px;
                    border: 1px solid;
                    margin-left: -8px;
                    font-size: 12px;
                    position: relative;
                    top: 0;
                    height: 19px;
                }
                & > .vz-tib-parent {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0 4px 0 3px;
                    border: 1px solid;
                    margin-left: -2px;
                    height: 19px;
                    line-height: 19px;
                }
            }
            &.vz-card-top {
                height: 20px;
                .vz-task-id-badge {
                    height: 20px;
                    & > span {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        height: 20px;
                        line-height: 19px;
                    }
                }
            }
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Tag('TaskIdBadgeComponent')
export class TaskIdBadgeComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() task?: Task;

    color: string = '#ccc';
    title: string = '';
    done: number = 0;
    total: number = 0;

    constructor(
        protected _store: StoreService,
        private _cdr: ChangeDetectorRef
    ) {
        super(_store);
    }

    ngOnInit(): void {
        this.update();
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.update();
    }

    update(): void {
        this.color = '#ccc';
        this.title = '';
        this.total = 0;
        this.done = 0;
        if (!this.task) {
            return;
        }
        this.color = string2Color(this.task.projectId);
        if (this.task.parentId) {
            this.title = 'Подзадача';
        }
        else if (this.task.subTasks?.length) {
            // this.total = this.task.subTasks.length;
            // this.done = this.task.subTasks.filter(t => t.archived || t.status == TaskStatus.Done).length;
            this.title = `Есть подзадачи`; // TODO, выполнено: ${this.done} из ${this.total}`;
        }
        this._cdr.markForCheck();
    }

}

@NgModule({
    imports: [CommonModule],
    declarations: [TaskIdBadgeComponent],
    exports: [TaskIdBadgeComponent]
})
export class TaskIdBadgeModule { }
