import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { AvatarModule } from '@shared/_views/avatar';

import { removeW } from '@base/base-animations';
import { BaseControlComponent } from '@base/base-control.component';
import { Project, SM, sortStringsBy, stopEvent } from '@models';
import { InputBoolean } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';

@Component({
    selector: 'vz-project-select',
    template: `
        <nz-select style="width: 100%"
            [class.pe-none]="readonly"
            [nzShowArrow]="!readonly"
            [formControl]="control"
            [nzMode]="multiple ? 'multiple' : 'default'"
            [nzMaxMultipleCount]="multiple ? 10 : INF"
            [nzCustomTemplate]="tplSelectItem"
            [nzPlaceHolder]="placeholder"
            [nzDisabled]="vzDisabled"
            [nzBorderless]="borderless || readonly"
            nzShowSearch
            [nzAllowClear]="allowClear">
            @for (it of allItems; track it.id) {
                <nz-option nzCustomContent [nzLabel]="it.name" [nzValue]="it.id">
                    <vz-avatar [item]="it" style="display: inline-block;" size="20" withName="true" type="project" asTag="true" />
                </nz-option>
            }
        </nz-select>
        @if (showClearButton && allowClear && !vzDisabled && ((!multiple && control.value)) || (multiple && control.value?.length)) {
            <button class="ant-btn-icon-only ant-btn-right" nz-button [nzType]="borderless ? 'text' : 'default'" (click)="se($event); control.setValue(multiple ? [] : null)" [@removeW]>
                <i class="vzi-cross txt-err"></i>
            </button>
        }
        <ng-template #tplSelectItem let-item>
            <vz-avatar [item]="itemsMap[item.nzValue]" style="display: inline-block;" size="20" withName="true" type="project" asTag="true" />
        </ng-template>
    `,
    styles: [':host { display: flex; flex: 1; flex-basis: 0px; }'],
    animations: [removeW],
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ProjectSelectComponent
        }
    ],
    imports: [
        CommonModule, ReactiveFormsModule,
        NzSelectModule, NzButtonModule,
        AvatarModule
    ]
})
export class ProjectSelectComponent extends BaseControlComponent implements OnChanges {
    @Input() placeholder: string = 'Выберите проект';
    @Input() allowedIds?: string[];
    @Input() @InputBoolean() vzDisabled: boolean | string = false;
    @Input() @InputBoolean() allowClear: boolean | string = false;
    @Input() @InputBoolean() showClearButton: boolean | string = true;
    @Input() @InputBoolean() borderless?: boolean | string = false;
    @Input() @InputBoolean() multiple: boolean | string = false;
    @Input() @InputBoolean() forTaskCreate: boolean | string = false;
    @Input() @InputBoolean() readonly?: boolean | string = false;

    itemsMap: SM<Project> = {};
    allItems: Project[] = [];
    INF = Infinity;
    se = stopEvent;

    constructor(protected _store: StoreService) {
        super(_store);
        this._S.items = this._store.state('projects').subscribe(st => this.filterItems(st.items));
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.filterItems(this._store.getState('projects').items);
    }

    vzOnActiveOrgChange(): void {
        this.filterItems(this._store.getState('projects').items);
    }

    filterItems(tm: SM<Project>): void {
        this.itemsMap = tm;
        const allItems = Object.values(this.itemsMap).filter(item => item.orgId == this.activeOrgId).sort(sortStringsBy('name'));
        if (this.granted['task.create'] || !this.forTaskCreate) {
            this.allItems = allItems.filter(p => !this.allowedIds || this.allowedIds.includes(p.id!));
        }
        else {
            this.allItems = allItems.filter(p => this.pGranted[p.id!] && this.pGranted[p.id!]['task.create'] && (!this.allowedIds || this.allowedIds.includes(p.id!)));
        }
        if (this.multiple) {
            const pids = (Array.isArray(this.control.value) ? this.control.value : []).filter(pid => this.allItems.findIndex(p => p.id == pid) != -1);
            this.control.setValue(pids);
        }
        else {
            const v = this.control.value;
            if (typeof v != 'string' || this.allItems.findIndex(p => p.id == v) == -1) {
                this.control.setValue(null);
            }
        }
    }

}
