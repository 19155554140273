import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { removeW } from '@base/base-animations';
import { BaseControlComponent } from '@base/base-control.component';
import { Group, Person, SM, removeFromArray, sortStringsBy, stopEvent } from '@models';
import { InputBoolean } from '@models/utils/coercions';
import { StoreService } from '@services/store.service';
import { AvatarModule } from '@shared/_views/avatar';

@Component({
    selector: 'person-or-group-select',
    template: `
        <nz-select style="width: 100%"
            [class.pe-none]="readonly"
            [nzShowArrow]="!readonly"
            [formControl]="control"
            [nzCustomTemplate]="tplSelectItem"
            [nzPlaceHolder]="placeholder"
            [nzMode]="multiple ? 'multiple' : 'default'"
            [nzMaxMultipleCount]="multiple ? 10 : INF"
            [nzBorderless]="borderless || readonly"
            [(nzOpen)]="ddOpen"
            [nzDropdownRender]="multiple ? closeTpl : null"
            [nzDisabled]="vzDisabled"
            [nzShowSearch]="true"
            [nzOptionHeightPx]="36"
            (nzOpenChange)="onOpenChange.emit($event)"
            [nzAllowClear]="allowClear">
            @if (hasSelf) {
                <nz-option nzCustomContent [nzLabel]="hasSelf.name" [nzValue]="hasSelf.id">
                    <vz-avatar style="display: inline-block; margin-top: 1px; padding-right: 4px;" [item]="hasSelf" size="22" withName="true"></vz-avatar>
                </nz-option>
            }
            @if (groups?.length) {
                @if (showPersons?.length) {
                    <nz-option-group nzLabel="Группы" >
                        @for (item of groups; track item.id) {
                            <nz-option nzCustomContent [nzLabel]="item.name!" [nzValue]="item.id">
                                <vz-avatar style="display: inline-block; margin-top: 1px; padding-right: 4px;" [item]="item" size="22" withName="true" type="group"></vz-avatar>
                            </nz-option>
                        }
                    </nz-option-group>
                    <nz-option-group nzLabel="Сотрудники">
                        @for (item of showPersons; track item.id) {
                            <nz-option nzCustomContent [nzLabel]="item.name" [nzValue]="item.id">
                                <vz-avatar style="display: inline-block; margin-top: 1px; padding-right: 4px;" [item]="item" size="22" withName="true"></vz-avatar>
                            </nz-option>
                        }
                    </nz-option-group>
                }
                @else {
                    @for (item of groups; track item.id) {
                        <nz-option nzCustomContent [nzLabel]="item.name!" [nzValue]="item.id">
                            <vz-avatar style="display: inline-block; margin-top: 1px; padding-right: 4px;" [item]="item" size="22" withName="true" type="group"></vz-avatar>
                        </nz-option>
                    }
                }
            }
            @else if (showPersons?.length) {
                @for (item of showPersons; track item.id) {
                    <nz-option nzCustomContent [nzLabel]="item.name" [nzValue]="item.id">
                        <vz-avatar style="display: inline-block; margin-top: 1px; padding-right: 4px;" [item]="item" size="22" withName="true"></vz-avatar>
                    </nz-option>
                }
            }
        </nz-select>
        @if (showClearButton && allowClear && !vzDisabled && ((!multiple && control.value)) || (multiple && control.value?.length)) {
            <button class="ant-btn-icon-only ant-btn-right" nz-button [nzType]="borderless ? 'text' : 'default'" (click)="se($event); control.setValue(multiple ? [] : null)" [@removeW]>
                <i class="vzi-cross txt-err"></i>
            </button>
        }
        <ng-template #tplSelectItem let-item>
            <vz-avatar
                [class.ml-025]="multiple"
                style="display: inline-block;"
                [style.margin-top.px]="multiple ? 1 : 4"
                [style.margin-right.px]="multiple ? 4 : 0"
                [uid]="item.nzValue"
                [size]="multiple ? 20 : 22"
                withName="true"
                [type]="isGroup[item.nzValue] ? 'group' : 'user'" />
        </ng-template>
        <ng-template #closeTpl>
            <div class="layout end-justified bc mh025" style="border-top: 1px solid">
                <a class="p025" (click)="ddOpen = false">закрыть</a>
            </div>
        </ng-template>
    `,
    styles: [':host { display: flex; flex: 1; flex-basis: 0px; }'],
    animations: [removeW],
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: UserOrGroupSelectComponent
        }
    ],
    imports: [
        CommonModule, ReactiveFormsModule,
        NzSelectModule, NzButtonModule,
        AvatarModule
    ],
})
export class UserOrGroupSelectComponent extends BaseControlComponent implements OnChanges {
    @Input() persons?: Person[];
    @Input() groups?: Group[];
    @Input() placeholder: string = 'Выберите пользователя или группу';
    @Input() @InputBoolean() vzDisabled: boolean | string = false;
    @Input() @InputBoolean() allowClear: boolean | string = false;
    @Input() @InputBoolean() showClearButton: boolean | string = true;
    @Input() @InputBoolean() multiple: boolean | string = false;
    @Input() @InputBoolean() borderless?: boolean | string = false;
    @Input() @InputBoolean() readonly?: boolean | string = false;
    @Output() onOpenChange: EventEmitter<boolean> = new EventEmitter();

    isGroup: SM<boolean> = {};
    hasSelf?: Person;
    showPersons?: Person[];
    ddOpen: boolean = false;

    INF = Infinity;
    se = stopEvent;

    constructor(protected _store: StoreService) {
        super(_store);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isGroup = {};
        if (changes.groups) {
            this.groups?.sort(sortStringsBy('name')).forEach(g => this.isGroup[g.id!] = true);
        }
        if (changes.persons) {
            this.showPersons = undefined;
            if (this.persons) {
                this.showPersons = [...this.persons].sort(sortStringsBy('name'));
                this.hasSelf = removeFromArray(this.showPersons, p => p.id == this.userId);
            }
            else {
                this.hasSelf = undefined;
            }
        }
    }

}
